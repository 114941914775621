<template>
  <v-dialog :value="dialog" width="800" scrollable persistent eager>
    <ValidationObserver ref="inquiryDialogObs">
      <v-card>
        <v-toolbar flat dark>
          <v-toolbar-title>1:1 문의 등록</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCloseClick()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-5 py-3">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-select
              v-model="inquiry.category"
              :error-messages="errors"
              :items="categoryList"
              label="문의 유형"
              required
              outlined
            ></v-select>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-textarea
              v-model="inquiry.content"
              :error-messages="errors"
              label="문의 내용"
              required
              outlined
              clearable
            ></v-textarea>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <v-text-field
              v-model="inquiry.email"
              :error-messages="errors"
              required
              label="연락받을 메일 주소"
              outlined
              clearable
            ></v-text-field>
          </ValidationProvider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="submit()" :loading="loading">
            등록
          </v-btn>
          <v-btn text @click="handleCloseClick()" :disabled="loading">
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { createInquiryApi } from '@/api/advertiser/UserAPI';
import { Inquiry } from '@/model/advertiser/Inquiry';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    title: {
      type: String,
      default: '문의하기'
    }
  },
  data() {
    return {
      loading: false,
      inquiry: new Inquiry(),
      categoryList: [
        {
          text: '정산',
          value: '정산'
        },
        {
          text: '기타',
          value: '기타'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      dialog: 'dialog/inquiryDialog'
    })
  },
  mounted() {
    this.initDialog();
  },
  methods: {
    ...mapActions({
      closeInquiryDialog: 'dialog/closeInquiryDialog'
    }),
    initDialog() {
      const inquiry = {
        category: null,
        content: null,
        email: null
      };
      this.inquiry = inquiry;
      this.$refs.inquiryDialogObs.reset();
    },
    async submit() {
      const valid = await this.$refs.inquiryDialogObs.validate();
      if (valid) {
        this.loading = true;

        const [error, data] = await createInquiryApi(this.inquiry);
        if (error) {
          console.error(error);
        } else {
          const { success } = data;
          if (success) {
            this.$emit('fetchInquiries');
            this.closeInquiryDialog();
          }
        }

        this.loading = false;
      }
    },
    handleCloseClick() {
      this.closeInquiryDialog();
    }
  }
};
</script>

<style></style>
